<template>
  <p class="column-navigation">
    &shy;
    <a
      id="ctl00_ContentPlaceHolderUniverse_HyperLinkLeft"
      class="play-left disabled update-url"
      rel="page=1"
      href="community/propertyoftheweek/halloffame.aspx?page=1"
      >&laquo;</a
    >

    1 of 6

    <a
      id="ctl00_ContentPlaceHolderUniverse_HyperLinkRight"
      class="play-right update-url"
      rel="page=2"
      href="community/propertyoftheweek/halloffame.aspx?page=2"
      >&raquo;</a
    >

    <label for="show">Show:</label>
    <select id="show" name="show" class="update-url">
      <option value="6" selected>6</option>
      <option value="9">9</option>
      <option value="12">12</option>
    </select>

    <label for="order">Order:</label>
    <select id="order" name="order" class="update-url">
      <option value="latest">Most Recent</option>
      <option value="oldest">Oldest</option>
    </select>
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {},
})
</script>
