<template>
  <div class="t-list innerHeight" id="RepeaterHallOfFame">
    <div class="t-row" v-for="property in properties" :key="property.id">
      <b><i></i></b><i><b></b></i><em></em>
      <div class="t-cell t-first cell-image">
        <router-link
          :to="{ name: 'community-property', params: { id: property.id } }"
          ><img :src="property.images[0].preview || property.images[0].url" width="120" height="90" class="rounded"
        /></router-link>
      </div>
      <div class="t-cell cell-description">
        <nexus-link :user="property.user"></nexus-link>
        <h4>
          <router-link
            :to="{ name: 'community-property', params: { id: property.id } }"
            >{{ property.name }}</router-link
          >
        </h4>
        <p>{{ property.description }}</p>
        <p class="small dateText">Property of the week {{ property.week }}</p>
      </div>
      <div class="t-cell cell-readmore">
        <router-link
          :to="{ name: 'community-property', params: { id: property.id } }"
          class="linkArrow"
          >See this property</router-link
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getProps } from "@/data/properties";

export default defineComponent({
  setup() {
    return {
      properties: getProps([354712, 354051, 353336, 352498, 351289, 350382]),
    };
  },
});
</script>


<style scoped lang="scss">
#RepeaterHallOfFame {
  .t-row {
    height: 110px;
  }

  .cell-description {
    width: 365px;

    span {
      float: right;
    }
  }
  .dateText {
    /*position: absolute;*/
    bottom: 10px;
  }

  .cell-readmore {
    width: 160px;
    a {
      position: absolute;
      bottom: 10px;
    }
  }
}
</style>