
import { defineComponent } from "vue";
import ColumnNavigation from "@/components/ColumnNavigation.vue";
import RepeaterHallOfFame from "@/components/RepeaterHallOfFame.vue";

export default defineComponent({
  components: {
    ColumnNavigation,
    RepeaterHallOfFame,
  },
  setup() {},
});
