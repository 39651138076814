
import { defineComponent } from "vue";
import { getProps } from "@/data/properties";

export default defineComponent({
  setup() {
    return {
      properties: getProps([354712, 354051, 353336, 352498, 351289, 350382]),
    };
  },
});
