<template>
  <div>
    <div
      class="column-row column-row-3"
      style="margin-bottom: 80px; height: 94px"
    >
      <div
        class="column column-large column-background first last"
        id="EventHallOfFameHeader"
      >
        <div class="column-content"></div>
      </div>
    </div>
    <text-module>
      <div class="negative-row">
        <column-navigation></column-navigation>
        <div class="hr"></div>
        <repeater-hall-of-fame></repeater-hall-of-fame>
      </div>
    </text-module>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ColumnNavigation from "@/components/ColumnNavigation.vue";
import RepeaterHallOfFame from "@/components/RepeaterHallOfFame.vue";

export default defineComponent({
  components: {
    ColumnNavigation,
    RepeaterHallOfFame,
  },
  setup() {},
});
</script>



<style scoped>
#EventHallOfFameHeader {
  height: 159px;
  background-image: url(../../assets/community/ingame-events/HallOfFameTop.png);
}
</style>